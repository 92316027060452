import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  elements,
  ChartDataset
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Card } from 'components';
import { Typography, Stack, IconButton } from '@mui/material';
import useVideo from 'hooks/video';
import InferenceVideo from './inferenceVideo';
import { Barchart } from 'components/charts/barchart';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface EventData {
  EventName: string;
  EventSource: string;
  Start: number;
  End: number;
  IsCorrect: boolean;
}

const convertData = (inputData: any): EventData[] => {
  const outputData: EventData[] = [];
  inputData.forEach((item: any) => {
    if (
      item.graph_data &&
      item.graph_data.activities &&
      item.graph_data.times &&
      item.graph_data.sequence
    ) {
      const activities = item.graph_data.activities;
      const times = item.graph_data.times;
      const sequences = item.graph_data.sequence;

      activities.forEach((activity: string, index: number) => {
        if (times[index]) {
          times[index].forEach(
            (
              time: { start_time: number; end_time: number },
              timeIndex: number
            ) => {
              if (time) {
                outputData.push({
                  EventName: activity,
                  EventSource: `${index + 1}`,
                  Start: time.start_time,
                  End: time.end_time,
                  IsCorrect: sequences[index][timeIndex]
                });
              }
            }
          );
        }
      });
    }
  });

  return outputData;
};

const GanttReport = ({
  getVideo,
  indefenceVideoData,
  openState,
  setOpenState
}: any) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [start, setStart] = useState<number | null>(null);
  const [end, setEnd] = useState<number | null>(null);
  const { videoRef, seek } = useVideo();
  const reportData = indefenceVideoData
    ? indefenceVideoData?.filter((data: any) => data?.name === getVideo)
    : [];

  const sampleData = convertData(reportData);

  // const labels: string[] = Array.from(
  //   new Set(sampleData.map(event => event.EventSource))
  // ).reverse();
  const labels = sampleData.reverse().map(e => e.EventName);

  const eventNames: string[] = Array.from(
    new Set(sampleData.map(event => event.EventName))
  );

  const eventColors: string[] = eventNames
    .map((val, i) => {
      const color = `hsl(${
        (i * (360 / (eventNames.length || 1))) % 360
      },100%,50%, 1)`;
      return color;
    })
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  interface StackData {
    Stack: string;
    LastDate: number;
  }

  const labelGrouping: { [key: string]: StackData[] } = {};

  const datasets: ChartDataset<'bar'>[] = sampleData.reverse().map(event => {
    return {
      label: event.EventName,
      data: [event.Start, event.End],
      barThickness: 'flex',
      backgroundColor: event.IsCorrect ? '#3F9827' : '#D02220'
    };
  });

  const data = {
    labels,
    datasets: [
      {
        labels: sampleData.reverse().map(event => event.EventName),
        data: sampleData.map(event => [event.Start, event.End]),
        backgroundColor: sampleData.map(event =>
          event.IsCorrect ? '#3F9827' : '#D02220'
        )
      }
    ]
  };

  const handleClick = (event: any, elements: any) => {
    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const index = element.index;
      const clickedDataset = data.datasets[datasetIndex];
      const startEnd = clickedDataset.data[index];

      if (startEnd) {
        const [start, end] = startEnd as [number, number, string];
        setStart(start);
        setEnd(end);
        setModalOpen(true);
      }
    }
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    elements: {
      bar: {
        // inflateAmount: 1.2,

        borderRadius: 0
      }
    },
    scales: {
      x: {
        type: 'linear',
        ticks: {
          display: true
        },
        min: 0,
        title: {
          display: true,
          text: 'Timeline'
        }
      },
      y: {
        type: 'category',
        labels,
        title: {
          display: true,
          text: 'Activities'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: context => {
            const modelName = reportData[0]?.model?.model_name;
            const event = sampleData[context.dataIndex];
            return `${event?.EventName}: ${event.Start} sec to ${event.End} sec / ${modelName}`;
          }
        }
      }
    },
    onClick: handleClick
  };

  return reportData?.length ? (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width={'100%'}
      >
        <Typography fontWeight={'bold'}>
          Activity Lifecycle- {getVideo}
        </Typography>
        <Stack>
          {openState === 0 ? (
            <IconButton onClick={() => setOpenState(3)}>
              <OpenInFullIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpenState(0)}>
              <CloseFullscreenIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Barchart data={data} options={options} openState={openState} />

      <InferenceVideo
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        start={start || 0}
        end={end || 0}
        videoRef={videoRef}
        getVideo={reportData[0]?.configuration_id}
        videoQuery={{ id: reportData[0]?.id }}
        setStart={setStart}
        seek={seek}
        videoUrl={reportData[0]?.video_url}
        videoName={reportData[0]?.name}
      />
    </Card>
  ) : (
    <></>
  );
};

export default GanttReport;
