import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { TextField } from 'components/Form';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { convertServerErrors } from 'utils/form';
import { Table, Typography, ITableHeadColumn } from 'components';
import { useSnack } from 'plugins/snack';
import { getViewerProfile, useDeleteMember, useRegisterMember } from 'api/sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useGetViewerProfile } from 'hooks/slurry/useViewerProfile';
import moment from 'moment';
import { FORMAT_DATE_TIME, dateToString, FORMAT_DATE } from 'utils';
import { useConfirm } from 'plugins/confirm';
import { ListViewerForm } from './ListViewerForm';

interface IChangeViewerFormProps {}
interface MessageData {
  message: string;
  // add other properties if needed
}
interface IChangeViewerFormValues {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
}

const initialValues: IChangeViewerFormValues = {
  name: '',
  email: '',
  password: '',
  confirm_password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required')
});

export const CreateViewerForm: React.FC<IChangeViewerFormProps> = () => {
  const { mutateAsync } = useRegisterMember();
  const navigate = useNavigate();
  const { data, refetch, isLoading } = useGetViewerProfile();

  useEffect(() => {
    refetch();
  }, []);

  const snack = useSnack();
  const confirm = useConfirm();

  // const handleSubmit =async()
  const handleSubmit = async (
    values: IChangeViewerFormValues,
    formikHelper: FormikHelpers<IChangeViewerFormValues>
  ) => {
    await mutateAsync({
      data: {
        email: values.email
      }
    })
      .then(response => {
        formikHelper.setValues(initialValues);
        formikHelper.setErrors({});
        formikHelper.setStatus({ success: 'Password changed successfully' });
        formikHelper.setSubmitting(false);
        formikHelper.setTouched({});
        refetch();
        const messageData = response?.data as MessageData;
        snack({
          message: messageData?.message,
          severity: 'success'
        });
      })
      .catch(error => {
        if (error.response?.status === 422) {
          formikHelper.setErrors(
            convertServerErrors(error.response.data.detail)
          );
        } else if (error.response?.status === 400) {
          formikHelper.setStatus({ error: error.response.data.detail });
        } else if (error.response?.status === 409) {
          formikHelper.setStatus({ error: error.response.data.detail });
        } else {
          formikHelper.setStatus({ error: 'An error occurred' });
        }
      });
  };

  return (
    <>
      <Typography
        style={{
          fontWeight: 'bold',
          color: 'black',
          fontSize: '16px',
          marginBottom: '30px'
        }}
      >
        Create Viewer Account
      </Typography>
      {/* Viewer List */}
      <ListViewerForm />

      <Formik<IChangeViewerFormValues>
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {/* <Grid item xl={6} lg={6} md={6} sm={12}>
                <TextField
                  name="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </Grid> */}
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <TextField
                  name="email"
                  label="Email ID"
                  type="email"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              {/* <Grid item xl={6} lg={6} md={6} sm={12}>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <TextField
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  size="small"
                />
              </Grid> */}
              <Grid
                item
                xl={3}
                lg={3}
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginLeft: '25%'
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Submitting..' : 'Create Viewer'}
                </Button>
              </Grid>
              {formik.status?.error && (
                <Grid item xs={12}>
                  <Typography color="error">{formik.status.error}</Typography>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
