/* eslint-disable */
import { Button, Grid } from '@mui/material';
import { TextField } from 'components/Form';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useChangePassword } from 'api/sdk';
import { convertServerErrors } from 'utils/form';
import { Typography } from 'components';
import { useSnack } from 'plugins/snack';

interface IChangePasswordFormProps {}

interface IChangePasswordFormValues {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const initialValues: IChangePasswordFormValues = {
  old_password: '',
  new_password: '',
  confirm_password: ''
};

const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Current password is required')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special character'),
  new_password: Yup.string()
    .required('New password is required')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special character'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password')],
    'Passwords must match'
  )
});

export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = () => {
  const { mutateAsync } = useChangePassword();
  const snack = useSnack();

  const handleSubmit = async (
    values: IChangePasswordFormValues,
    formikHelper: FormikHelpers<IChangePasswordFormValues>
  ) => {
    await mutateAsync({
      data: {
        new_password: values.new_password,
        old_password: values.old_password
      }
    })
      .then(() => {
        formikHelper.setValues(initialValues);
        formikHelper.setErrors({});
        formikHelper.setStatus({ success: 'Password changed successfully' });
        formikHelper.setSubmitting(false);
        formikHelper.setTouched({});
        snack({
          message: 'Password changed successfully',
          severity: 'success'
        });
      })
      .catch(error => {
        if (error.response?.status === 422) {
          formikHelper.setErrors(
            convertServerErrors(error.response.data.detail)
          );
        } else {
          formikHelper.setStatus({ error: 'An error occurred' });
        }
      });
  };

  return (
    <>
      <Formik<IChangePasswordFormValues>
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={6} sm={12}>
                <TextField
                  name="old_password"
                  label="Current Password"
                  type="password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xl={3} lg={3} md={6} sm={12}>
                <TextField
                  name="new_password"
                  label="New Password"
                  type="password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xl={3} lg={3} md={6} sm={12}>
                <TextField
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Submitting..' : 'Change Password'}
                </Button>
              </Grid>
              {formik.status?.error && (
                <Grid item xs={12}>
                  <Typography color="error">{formik.status.error}</Typography>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
