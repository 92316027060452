import { TimelineState } from '@xzdarcy/react-timeline-editor';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid, LinearProgress, Popover, Stack, Tooltip } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  convertDurationToSeconds,
  hrsToMnsToSecToMlSec,
  secondsToFriendlyTime,
  secondsToHms
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Button, Modal, Typography } from 'components';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import RedoSharpIcon from '@mui/icons-material/RedoSharp';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  addActivity,
  addClip,
  applyToAllVideos,
  deleteAllClips,
  deleteClip
} from 'store/config/activities';
import { useDispatch } from 'react-redux';
import applyToAll from '../../../assets/images/applyToAll.svg';
import replicate from '../../../assets/images/replicate.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ConfigCameras } from 'store/config/schema';
import { useSnack } from 'plugins/snack';
import { useAutoLabel } from 'api/sdk';

const TimelinePlayer: FC<{
  timelineState: React.MutableRefObject<TimelineState | null>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  maxEndtime: number;
  activity: any;
  editorData?: any;
  setLabelLoader?: any;
  loadingVideo: boolean;
  videoPath?: any;
  setSelectedActivity?: string;
  selectedActivity?: any;
  disableCrop: boolean;
  currentCamera?: ConfigCameras;
  currentCameraId?: string;
  showText: boolean;
  onClickAdd: () => void;
  currentConfigId?: string | undefined;
  setZoomCOunt: React.Dispatch<React.SetStateAction<number>>;
  zoomCount: number;
  time: number;
  activityData: any;
  activityDataPointer: number;
  setActivityDataPointer: React.Dispatch<React.SetStateAction<number>>;
  onClickCrop: () => void;
  handlePlay: () => void;
  handlePause: () => void;
  isPlaying: boolean;
  setActivityData: any;
  isCurrentVideoEnabled?: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  getCropState: any;
}> = ({
  timelineState,
  videoRef,
  setLabelLoader,
  handlePlay,
  currentConfigId,
  handlePause,
  isCurrentVideoEnabled,
  showText,
  loadingVideo,
  onClickAdd,
  isPlaying,
  onClickCrop,
  setActivityData,
  time,
  disableCrop,
  videoPath,
  editorData,
  activity,
  currentCamera,
  getCropState,
  activityData,
  currentCameraId,
  setActivityDataPointer,
  setIsPlaying,
  activityDataPointer,
  setZoomCOunt,
  selectedActivity,
  zoomCount
}) => {
  const dispatch = useDispatch();
  const snack = useSnack();
  const { mutateAsync, isLoading } = useAutoLabel();
  const [isOpen, setOpen] = useState({ open: false, value: '' });
  const allVideos = useSelector((state: any) => state?.configV2.cameras);
  const [openPop, setPopOpen] = useState(false);
  const [labelPop, setLabelPop] = useState(false);
  const [selectedLableSource, setSelectedLableSource] = useState<
    string | undefined
  >();
  const allactivities = useSelector(
    (state: RootState) => state.activities.data
  );
  const replicated = useSelector((state: any) => state?.config?.replicate);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [sourceCameraId, setSourceCameraId] = useState<string | undefined>('');
  const handleCheckboxChange = (event: any, option: any) => {
    if (event.target.checked) {
      setSelectedItems((prevSelected: any) => [...prevSelected, option]);
    } else {
      setSelectedItems((prevSelected: any) =>
        prevSelected.filter((item: any) => item !== option)
      );
    }
  };

  const transformActivities = useMemo(() => {
    // Create a map to group clips by camera_id
    const cameraGroupedClips = allactivities.reduce(
      (acc: any, activity: any) => {
        activity.clips.forEach((clip: any) => {
          if (!acc[clip.camera_id]) {
            acc[clip.camera_id] = {
              cameraId: clip.camera_id,
              clips: [],
              videoUrl: '' // Assuming videoUrl is empty as it's not in the original data
            };
          }

          acc[clip.camera_id].clips.push({
            ...clip,
            activity: activity.activity // Adding activity name to each clip for reference
          });
        });

        return acc;
      },
      {}
    );

    // Convert the map to array
    return Object.values(cameraGroupedClips);
  }, [allactivities]);

  const assistSourceData = useMemo(() => {
    return activity
      .map((data: any) => ({
        ...data,
        clips: data.clips.filter(
          (clip: any) => clip.camera_id === selectedLableSource
        )
      }))
      .filter((activity: any) => activity.clips.length > 0);
  }, [selectedLableSource]);

  const labelAssisData = useSelector(
    (state: any) => state?.config?.labelAssistData
  );

  function checkAllActivities(value: string) {
    let count = 0;
    allactivities.forEach((data: any) => {
      if (data?.clips.length) {
        count += 1;
      }
    });
    if (count === 0) {
      setOpen({ open: true, value: value });
    } else if (count !== 0 && value === 'Apply to All') {
      setPopOpen(true);
    }
  }

  // call api with data.
  const callLabelAssist = async (): Promise<void> => {
    await mutateAsync({
      params: { config_id: currentConfigId || '' },
      data: {
        config_id: currentConfigId || '',
        source_annotation: assistSourceData,
        source_camera_id: selectedLableSource || '',
        source_video_path: allVideos.filter(
          (data: any) => data.id === selectedLableSource
        )[0]?.video_path,
        target_cameras: { [currentCameraId || '']: videoPath }
      }
    });
    setLabelLoader(true);
    setLabelPop(false);
    return;
  };

  useEffect(() => {
    if (!timelineState) return;
    if (!timelineState.current) return;

    const engine = timelineState.current;

    if (!isPlaying) {
      engine.listener.on('afterSetTime', ({ time }) => {
        if (videoRef.current) videoRef.current.currentTime = time;
      });
    }

    return () => {
      if (!engine) return;
      engine.pause();
      engine.listener.offAll();
    };
  }, [isPlaying]);

  function applyOnce() {
    editorData.length &&
      editorData.map((data: any) => {
        data.clips.map((clips: any) => {
          dispatch(
            deleteClip({
              activityId: data.id,
              clipId: clips.id
            })
          );
        });
      });
    replicated[0]?.map((data: any) => {
      data?.clips?.map((clips: any) => {
        const clipData = {
          clip_id: uuidv4(),
          cameraId: currentCameraId as string,
          start_time: clips.start_time,
          end_time: clips.end_time,
          selectedActivity: data.id,
          videoUrl: currentCamera?.video_url || '',
          activityName: data.activity,
          max_duration: videoRef.current?.duration || undefined
        };
        dispatch(addClip(clipData));
      });
    });
  }

  useEffect(() => {
    editorData.length &&
      editorData.map((data: any) => {
        data.clips.map((clips: any) => {
          dispatch(
            deleteClip({
              activityId: data.id,
              clipId: clips.id
            })
          );
        });
      });
    labelAssisData?.map((data: any) => {
      data.clips.map((clips: any) => {
        const clipData = {
          clip_id: uuidv4(),
          cameraId: currentCameraId as string,
          start_time: convertDurationToSeconds(clips.start_time),
          end_time: convertDurationToSeconds(clips.end_time),
          selectedActivity: data.id,
          videoUrl: currentCamera?.video_url || '',
          activityName: data.activity,
          max_duration: videoRef.current?.duration || undefined
        };
        dispatch(addClip(clipData));
      });
    });
  }, [labelAssisData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (timelineState.current && event.code === 'Space') {
        event.preventDefault();
        if (isPlaying) {
          videoRef.current?.pause();
          setIsPlaying(false);
          timelineState.current.pause();
        } else {
          videoRef.current?.play();
          setIsPlaying(true);
          timelineState.current.play({ autoEnd: true });
        }
      }
      if (
        event.ctrlKey &&
        event.key === 'x' &&
        !getCropState?.start
          .toString()
          .includes(videoRef.current?.currentTime.toString())
      ) {
        event.preventDefault();
        return !disableCrop && !loadingVideo && !showText && onClickCrop();
      }
      if (event.ctrlKey && event.key === 'a') {
        event.preventDefault();
        return !loadingVideo && !showText && onClickAdd();
      }
      if (event.ctrlKey && event.key === 'z') {
        // Ctrl+Z for undo
        event.preventDefault();
        if (activityDataPointer > 1) {
          setActivityDataPointer(activityDataPointer - 1);
        }
      } else if (event.ctrlKey && event.key === 'y') {
        // Ctrl+Y for redo
        event.preventDefault();
        if (activityDataPointer < activityData.length - 1) {
          setActivityDataPointer(activityDataPointer + 1);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activityDataPointer, activityData, isPlaying]);

  function onSourceChange(data: any) {
    // Set the source camera ID from the incoming data
    setSourceCameraId(data?.cameraId);

    // Create a new array excluding the selected camera ID
    const filteredItems = selectedItems.filter(
      (item: any) => item !== data?.cameraId
    );

    // Update the state with the filtered items
    setSelectedItems(filteredItems);
  }

  return (
    <>
      <Grid
        container
        gap={1}
        style={{
          borderBottom: '1px solid #e0e0e0'
        }}
        m={1}
      >
        <Grid item xs={4}>
          <Stack
            spacing={1}
            direction="row"
            pl={2}
            height={'100%'}
            alignItems={'center'}
            alignContent={'center'}
          >
            <div className="play-control">
              <Tooltip title="Label Assist" placement="top-start">
                <AutoFixHighIcon
                  style={{
                    height: 20,
                    cursor: 'pointer',
                    fill: isCurrentVideoEnabled ? 'black' : 'grey'
                  }}
                  onClick={() => {
                    isCurrentVideoEnabled && checkAllActivities('Label Assist');
                    isCurrentVideoEnabled && setLabelPop(true);
                  }}
                />
              </Tooltip>
            </div>
            <Popover
              open={labelPop}
              style={{ marginLeft: '200px', marginBottom: '20px' }}
              onClose={() => setLabelPop(false)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              <Typography sx={{ p: 2 }} style={{ fontWeight: 'bold' }}>
                Select To Apply Label Assist
              </Typography>

              {/* Multiselect inside a div */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px'
                }}
              >
                <div style={{ padding: '10px', minWidth: '200px' }}>
                  <div>Source Video</div>
                  {transformActivities.map(
                    (data: any, index: number) =>
                      data?.clips?.length > 0 && (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          <input
                            type="checkbox"
                            value={data}
                            id={data?.cameraId}
                            checked={selectedLableSource === data?.cameraId}
                            onChange={() =>
                              setSelectedLableSource(data?.cameraId)
                            }
                          />
                          <label
                            htmlFor={data?.cameraId}
                            style={{ marginLeft: '8px', fontWeight: 'bold' }}
                          >
                            {allVideos.map(
                              (video: any) =>
                                video.id === data?.cameraId && video.name
                            )}
                          </label>
                        </div>
                      )
                  )}
                </div>
                {/* <div style={{ padding: '10px', minWidth: '200px' }}>
                  <div>Destination Video</div>
                  {allVideos.map((option: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold'
                      }}
                    >
                      <input
                        type="checkbox"
                        value={option}
                        id={option?.id}
                        checked={selectedItems.includes(option?.id)}
                        onChange={e =>
                          option.id !== sourceCameraId &&
                          handleCheckboxChange(e, option?.id)
                        }
                      />
                      <label
                        htmlFor={option?.id}
                        style={{
                          marginLeft: '8px',
                          fontWeight: 'bold',
                          color: option.id === sourceCameraId ? 'grey' : 'black'
                        }}
                      >
                        {option?.name}
                      </label>
                    </div>
                  ))}
                </div> */}
              </div>
              <div
                style={{
                  padding: '15px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  style={{ height: '25px', width: '30px', margin: '10px' }}
                  variant="outlined"
                  color="primary"
                  version="light"
                  size="small"
                  children={'Cancel'}
                  onClick={() => setLabelPop(false)}
                />
                <Button
                  style={{ height: '25px', width: '30px', margin: '10px' }}
                  variant="outlined"
                  color="primary"
                  version="dark"
                  size="small"
                  children={'Apply'}
                  onClick={() => {
                    isCurrentVideoEnabled &&
                      activity?.length &&
                      callLabelAssist();
                  }}
                />
              </div>
            </Popover>
            {/* <div className="play-control">
              <Tooltip title="Replicate" placement="top-start">
                <img
                  src={replicate}
                  style={{
                    height: 18,
                    cursor: 'pointer',
                    fill:
                      isCurrentVideoEnabled && replicated[0]?.length
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() => {
                    isCurrentVideoEnabled &&
                      replicated[0]?.length &&
                      checkAllActivities('Replicate');
                    isCurrentVideoEnabled &&
                      replicated[0]?.length &&
                      applyOnce();
                  }}
                />
              </Tooltip>
            </div> */}

            <Popover
              // id={id}
              open={openPop}
              // anchorEl={anchorEl}
              style={{ marginLeft: '200px', marginBottom: '20px' }}
              onClose={() => setPopOpen(false)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              <Typography sx={{ p: 2 }} style={{ fontWeight: 'bold' }}>
                Select To Apply
              </Typography>

              {/* Multiselect inside a div */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px'
                }}
              >
                <div style={{ padding: '10px', minWidth: '200px' }}>
                  <div>Source Video</div>
                  {transformActivities.map(
                    (data: any, index: number) =>
                      data?.clips?.length > 0 && (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          <input
                            type="checkbox"
                            value={data}
                            id={data?.cameraId}
                            checked={sourceCameraId === data?.cameraId}
                            onChange={() => onSourceChange(data)}
                          />
                          <label
                            htmlFor={data?.cameraId}
                            style={{ marginLeft: '8px', fontWeight: 'bold' }}
                          >
                            {allVideos.map(
                              (video: any) =>
                                video.id === data?.cameraId && video.name
                            )}
                          </label>
                        </div>
                      )
                  )}
                </div>
                <div style={{ padding: '10px', minWidth: '200px' }}>
                  <div>Destination Video</div>
                  {allVideos.map((option: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold'
                      }}
                    >
                      <input
                        type="checkbox"
                        value={option}
                        id={option?.id}
                        checked={selectedItems.includes(option?.id)}
                        onChange={e =>
                          option.id !== sourceCameraId &&
                          handleCheckboxChange(e, option?.id)
                        }
                      />
                      <label
                        htmlFor={option?.id}
                        style={{
                          marginLeft: '8px',
                          fontWeight: 'bold',
                          color: option.id === sourceCameraId ? 'grey' : 'black'
                        }}
                      >
                        {option?.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  padding: '15px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  style={{ height: '25px', width: '30px', margin: '10px' }}
                  variant="outlined"
                  color="primary"
                  version="light"
                  size="small"
                  children={'Cancel'}
                  onClick={() => setPopOpen(false)}
                />
                <Button
                  style={{ height: '25px', width: '30px', margin: '10px' }}
                  variant="outlined"
                  color="primary"
                  version="dark"
                  size="small"
                  children={'Apply'}
                  onClick={() => {
                    sourceCameraId &&
                      dispatch(
                        applyToAllVideos({
                          currentCameraId: sourceCameraId,
                          allCameraIds: selectedItems
                        })
                      );
                    setPopOpen(false);
                    snack({
                      message: 'Applied to all videos',
                      severity: 'success'
                    });
                  }}
                />
              </div>
            </Popover>

            {/* The rest of your original component */}
            <div className="play-control">
              <Tooltip title="Apply on Selected" placement="top-start">
                <img
                  src={applyToAll}
                  style={{
                    height: 18,
                    cursor: 'pointer',
                    fill: isCurrentVideoEnabled ? 'black' : 'grey'
                  }}
                  onClick={() => {
                    isCurrentVideoEnabled && checkAllActivities('Apply to All');
                  }}
                />
              </Tooltip>
            </div>

            <div className="play-control">
              <Tooltip title="Add Activity" placement="top-start">
                <AddIcon
                  style={{
                    height: 20,
                    cursor: 'pointer',
                    fill:
                      isCurrentVideoEnabled && !loadingVideo && !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    isCurrentVideoEnabled &&
                    !loadingVideo &&
                    !showText &&
                    onClickAdd()
                  }
                />
              </Tooltip>
            </div>
            {/* HERE */}
            <div className="play-control">
              <Tooltip title="Undo" placement="top-start">
                <UndoSharpIcon
                  style={{
                    height: 20,
                    cursor:
                      isCurrentVideoEnabled &&
                      activityDataPointer > 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      isCurrentVideoEnabled &&
                      activityDataPointer > 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    isCurrentVideoEnabled &&
                    !loadingVideo &&
                    !showText &&
                    activityDataPointer > 1 &&
                    setActivityDataPointer(activityDataPointer - 1)
                  }
                />
              </Tooltip>
            </div>
            <div className="play-control">
              <Tooltip title="Redo" placement="top-start">
                <RedoSharpIcon
                  style={{
                    height: 20,
                    cursor:
                      isCurrentVideoEnabled &&
                      activityDataPointer < activityData.length - 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      isCurrentVideoEnabled &&
                      activityDataPointer < activityData.length - 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    isCurrentVideoEnabled &&
                    !loadingVideo &&
                    !showText &&
                    activityDataPointer < activityData.length - 1 &&
                    setActivityDataPointer(activityDataPointer + 1)
                  }
                />
              </Tooltip>
            </div>
            <div className="play-control">
              <Tooltip title="Cut Activity" placement="top-start">
                <ContentCutIcon
                  style={{
                    height: 20,
                    cursor:
                      isCurrentVideoEnabled &&
                      !disableCrop &&
                      !loadingVideo &&
                      !showText &&
                      !getCropState?.start
                        .toString()
                        .includes(videoRef.current?.currentTime.toString())
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      (isCurrentVideoEnabled &&
                        getCropState?.start
                          .toString()
                          .includes(
                            videoRef.current?.currentTime.toString()
                          )) ||
                      disableCrop ||
                      loadingVideo ||
                      showText
                        ? 'grey'
                        : 'black'
                  }}
                  onClick={
                    isCurrentVideoEnabled &&
                    !getCropState?.start
                      .toString()
                      .includes(videoRef.current?.currentTime.toString()) &&
                    !disableCrop &&
                    !loadingVideo &&
                    !showText
                      ? onClickCrop
                      : () => {}
                  }
                />
              </Tooltip>
            </div>
            {/* ZOOM  */}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            height={'100%'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="caption">
              {hrsToMnsToSecToMlSec(time || 0)} /
              {hrsToMnsToSecToMlSec(videoRef.current?.duration || 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3.5}>
          <Stack
            spacing={1}
            direction="row"
            pl={2}
            height={'100%'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'flex-end'}
          >
            <div className="play-control">
              <Tooltip title="Zoom out" placement="top-start">
                <ZoomOutIcon
                  style={{
                    height: 20,
                    cursor:
                      isCurrentVideoEnabled &&
                      zoomCount > 0 &&
                      !loadingVideo &&
                      !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      isCurrentVideoEnabled &&
                      zoomCount > 0 &&
                      !loadingVideo &&
                      !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    isCurrentVideoEnabled &&
                    !loadingVideo &&
                    !showText &&
                    zoomCount > 0 &&
                    setZoomCOunt(zoomCount - 1)
                  }
                />
              </Tooltip>
            </div>

            <div className="play-control">
              <Tooltip title="Zoom in" placement="top-start">
                <ZoomInIcon
                  style={{
                    height: 20,
                    cursor:
                      isCurrentVideoEnabled &&
                      zoomCount < 2 &&
                      !loadingVideo &&
                      !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      isCurrentVideoEnabled &&
                      zoomCount < 2 &&
                      !loadingVideo &&
                      !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    isCurrentVideoEnabled &&
                    !loadingVideo &&
                    !showText &&
                    zoomCount < 2 &&
                    setZoomCOunt(zoomCount + 1)
                  }
                />
              </Tooltip>
            </div>
          </Stack>
        </Grid>
        <Modal
          open={isOpen.open}
          onClose={() => setOpen({ open: false, value: '' })}
          size="md"
        >
          <div style={{ fontWeight: 'bold' }}>{isOpen.value}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '10px 0px 15px 0px'
            }}
          >
            To enable {isOpen.value} you have to annotate manually the
            activities atleast for one video. Once that's done, it will appear
            here to select.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => setOpen({ open: false, value: '' })}>
              OK
            </Button>
          </div>
        </Modal>
      </Grid>
    </>
  );
};

export default TimelinePlayer;
