/* eslint-disable  */
import { el } from '@faker-js/faker';
import Env from 'config/env';
import { useWebSocket } from 'hooks/websocket';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  consumeWebsocketMessage,
  consumesocketTrainingMessage
} from 'store/config/objects';

import { consumeWebsocketActivityMessage } from 'store/config/activities';
import {
  PipelineStatusEnum,
  setLabelAssist,
  updatePipelineStatus
} from 'store/configSlice';
import { updateVideoData, updateVideoPath } from 'store/config/configSlice';

export const useConfigWebsocket = (id: string) => {
  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${id}`;

  const { socket, connectionStatus, sendMessage, messages } = useWebSocket(
    socketUrl || ''
  );

  const dispatch = useDispatch();

  const handleMessagesReceived = (message: any) => {
    const messageType = message?.type;
    const data = message?.data;
    const cameraId = data?.camera_id;

    const pipelineType = message?.pipeline_type || message?.type;

    switch (pipelineType) {
      case 'auto_label':
        // console.log('auto_labelssss', message.data.data);
        dispatch(
          consumeWebsocketMessage({
            type: messageType,
            data: message.data.data
          })
        );
        dispatch(setLabelAssist(message.data.data));
        break;
      case 'training_assist':
        dispatch(
          consumesocketTrainingMessage({
            type: messageType,
            data: message.data
          })
        );
        break;
      case 'activity_assist':
        dispatch(
          consumeWebsocketActivityMessage({
            type: messageType,
            data: message.data
          })
        );
        break;

      case 'video_conversion':
        dispatch(
          updateVideoPath({
            cameraId: data.camera_id,
            lowres_url: data.lowres_url
          })
        );
        break;

      default:
        break;

      // if (messageType === 'PROCESSING') {
      //   dispatch(
      //     consumeWebsocketMessage([
      //       {
      //         id: cameraId,
      //         status: PipelineStatusEnum.PROCESSING,
      //         progress: 0
      //       }
      //     ])
      //   );
      // } else if (messageType === 'PROGRESS') {
      //   dispatch(
      //     consumeWebsocketMessage([
      //       {
      //         id: cameraId,
      //         status: PipelineStatusEnum.PROCESSING,
      //         progress: data?.progress
      //       }
      //     ])
      //   );
      // } else if (messageType === 'COMPLETED') {
      //   dispatch(
      //     consumeWebsocketMessage([
      //       {
      //         id: cameraId,
      //         status: PipelineStatusEnum.COMPLETED,
      //         progress: 100,
      //         objects: data?.object_details
      //       }
      //     ])
      //   );
      // } else if (messageType === 'FAILED') {
      //   dispatch(
      //     consumeWebsocketMessage([
      //       {
      //         id: cameraId,
      //         status: PipelineStatusEnum.FAILED
      //       }
      //     ])
      //   );
      // }
    }
  };

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', (event: any) => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  return {
    socket,
    connectionStatus,
    sendMessage,
    messages
  };
};
